import { useState, useEffect, useContext, Fragment } from 'react';
import { useUser, useAuth } from '@clerk/clerk-react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, QuestionMarkCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { Mixpanel } from '../services/Mixpanel';
import SignInButton from './SignInButton';
import Notices from './Notice';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  daysLeftInTrial,
  isFreeTrialUser,
  isActiveIndividualSubscription,
  hasActiveSubscription,
  isPremiumUser,
  userHasEnteredSchoolRecord,
  userHasCompletedTrialExtensionSurvey,
  isInPremiumOrg,
} from '../utils/isPremiumUser';
import PremiumIcon from './PremiumIcon';
import { DiffitUserContext } from '../contexts/DiffitUserContext';
import { useFlags } from 'launchdarkly-react-client-sdk';
import BasicModal from './modals/BasicModal';
import { getUsersSchoolCode, getUsersShareCode } from '../services/PromoCodeService';
import { getSchoolFromUser } from '../utils/users';
import SignInModal from './SignInModal';
import Image from './Image';
import { clearActivitiesCache } from '../routes/Activities/activities';
import { ExtendTrialWithSchoolInfo, ShareSchoolPromoCode } from './ExtendTrialWithSchoolInfo';
import { ExtendTrialWithSurvey } from './ExtendTrialWithSurvey';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// activity/:activityId
// activity/:activityId/:packetId
// activities
// activities/:packetId
const regex = /^\/activity\/[^\/]+(\/[^\/]+)?$|^\/activities(\/[^\/]+)?$/;

const isOnActivityBrowserRoot = (path) => {
  return ['/activities', '/activities/'].includes(path);
};

export const isOnActivityPath = (path) => {
  return regex.test(path);
};

export default function Header({ setShowOnboardingWizard, showOnboardingWizard }) {
  const { user } = useUser();
  const { diffitUser, selectedOrg } = useContext(DiffitUserContext);
  const { signOut, isSignedIn, isLoaded } = useAuth();
  const {
    enableMixMatchPreviewUi,
    enableMyBinderView,
    returningUserOnboardingExperience,
    newUserOnboardingExperience,
    switchTrialExtensionFlowToSurvey,
  } = useFlags();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [existingPromoCode, setExistingPromoCode] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [didRedeem, setDidRedeem] = useState(false);
  const [showPendingNotification, setShowPendingNotification] = useState(false);
  const location = useLocation();
  const { packetId } = useParams();

  const handleSignOut = () => {
    signOut();
    Mixpanel.track('Sign Out');

    if (isOnActivityPath(location.pathname)) {
      // these views show things specific to the user that *was* signed in (e.g. their custom templates, their favorite tempaltes, etc)
      // on signout, let's remove these from the cache and reload immediately
      clearActivitiesCache();
      window.location.reload();
    }
  };

  useEffect(() => {
    if (!diffitUser) return;
    // Check NUX
    const seenNux = diffitUser.seen_nux_json ? JSON.parse(diffitUser.seen_nux_json) : {};
    if (
      !showOnboardingWizard &&
      returningUserOnboardingExperience === 'notification' &&
      !seenNux['user_completed_onboarding']
    ) {
      Mixpanel.track('Showed pending onboarding notification - v2');
      setShowPendingNotification(true);
    } else if (
      !showOnboardingWizard &&
      returningUserOnboardingExperience === 'wizard' &&
      !seenNux['user_completed_onboarding'] &&
      !sessionStorage.getItem('seenOnboardingWizard')
    ) {
      Mixpanel.track('Showed retuning user onboarding wizard - v2');
      sessionStorage.setItem('seenOnboardingWizard', true);
      setShowOnboardingWizard(true);
    }
  }, [diffitUser, returningUserOnboardingExperience]);

  useEffect(() => {
    if (searchParams.has('extend_with_school') && isLoaded) {
      if (diffitUser) {
        if (
          shouldShowTrialExtensionOption(diffitUser) &&
          !isInPremiumOrg(diffitUser) &&
          !isActiveIndividualSubscription(diffitUser)
        ) {
          Mixpanel.track('Showed extend_with_school upgrade modal');
          setShowUpgradeModal(true);
        } else {
          Mixpanel.track('Ineligible user hit extend_with_school param', {
            userHasEnteredSchoolRecord: userHasEnteredSchoolRecord(diffitUser),
            isInPremiumOrg: isInPremiumOrg(diffitUser),
            isActiveIndividualSubscription: isActiveIndividualSubscription(diffitUser),
          });
        }
        searchParams.delete('extend_with_school');
        setSearchParams(searchParams);
      } else {
        if (!isSignedIn) {
          Mixpanel.track('Showed sign in modal in extend_with_school flow');
          setShowSignInModal(true);
        }
      }
    }
  }, [searchParams, isSignedIn, isLoaded, diffitUser]);

  const shouldShowTrialExtensionOption = () => {
    if (switchTrialExtensionFlowToSurvey) {
      return !userHasCompletedTrialExtensionSurvey(diffitUser);
    } else {
      return !userHasEnteredSchoolRecord(diffitUser);
    }
  };

  const dismissPromoCodeModal = () => {
    Mixpanel.track('Share School Promo Code Modal Closed');
    setExistingPromoCode(false);
  };

  const closeModal = (shouldReload) => {
    setShowUpgradeModal(false);
    if (shouldReload) {
      // reload page for Clerk so that they can see the new premium features
      window.location.reload();
    }
  };
  const isOnAnActivityPage = isOnActivityPath(window.location.pathname);

  return (
    <>
      {showSignInModal && <SignInModal open={showSignInModal} setOpen={setShowSignInModal} />}
      <BasicModal
        showModal={showUpgradeModal}
        setShowModal={() => {
          closeModal(didRedeem);
        }}>
        {switchTrialExtensionFlowToSurvey ? (
          <ExtendTrialWithSurvey
            onClose={() => {
              closeModal();
            }}
          />
        ) : (
          <ExtendTrialWithSchoolInfo
            onClose={(shouldReload) => {
              // remove extend_with_school from the url if it exists
              searchParams.delete('extend_with_school');
              closeModal(shouldReload);
            }}
            onRedeem={() => {
              // used to determine if we need to refresh the page when the modal closes
              setDidRedeem(true);
            }}
          />
        )}
      </BasicModal>
      <BasicModal
        showModal={!!existingPromoCode}
        setShowModal={dismissPromoCodeModal}
        icon={<PremiumIcon className='h-6 w-6 text-purple-600' aria-hidden='true' />}
        iconBackground={'bg-purple-100'}>
        <ShareSchoolPromoCode
          promoCode={existingPromoCode}
          schoolName={switchTrialExtensionFlowToSurvey ? '' : getSchoolFromUser(diffitUser).name}
          onClose={dismissPromoCodeModal}
        />
      </BasicModal>
      <div>
        <Image className='mt-2 h-8 w-auto hidden print:flex' src='/logo.svg' alt='Diffit' />
      </div>
      <Disclosure as='nav' className='bg-white shadow print:hidden'>
        {({ open }) => (
          <>
            <div id='main-nav-bar' className={`mx-auto px-6 sm:px-6 lg:px-8`}>
              <div className='flex h-20 justify-between'>
                <div className='flex'>
                  <a
                    href='/'
                    className='flex flex-col justify-center'
                    onClick={() => Mixpanel.track('Header click', { item: 'Logo' })}>
                    <div>
                      <Image className='h-12 w-auto' src='/logo.svg' alt='Diffit' />
                    </div>
                  </a>
                  {!isSignedIn && isLoaded && (
                    <div className='hidden sm:ml-10 sm:flex sm:space-x-8'>
                      {/* Current: "border-emerald-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                      <a
                        role='button'
                        onClick={() => {
                          Mixpanel.track('Header click', { item: 'Start New' });
                          navigate('/');
                        }}
                        className={`inline-flex items-center px-1 pt-1 text-sm text-gray-500 border-b-2 border-white hover:border-emerald-300 hover:border-gray-300 hover:text-gray-900`}>
                        Start New
                      </a>
                      {enableMyBinderView && (
                        <a
                          role='button'
                          onClick={() => {
                            Mixpanel.track('Header click', {
                              item: 'Activities',
                            });
                            clearActivitiesCache();
                            navigate('/activities');
                            if (isOnActivityBrowserRoot(location.pathname)) {
                              window.location.reload();
                            }
                          }}
                          className={`inline-flex items-center  px-1 pt-1 text-sm border-b-2 border-white hover:border-gray-300 hover:text-gray-900 ${
                            isOnAnActivityPage
                              ? 'border-emerald-500 text-gray-500'
                              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-900'
                          }`}>
                          Activities
                        </a>
                      )}
                      <a
                        href='https://web.diffit.me/professional-development'
                        target='_blank'
                        onClick={() => Mixpanel.track('Header click', { item: 'Learn More' })}
                        className='inline-flex items-center  border-transparent px-1 pt-1 text-sm text-gray-500 hover:border-gray-300 hover:text-gray-900 border-b-2 border-white hover:border-emerald-300 hover:text-gray-900'>
                        Learn More
                      </a>
                      <a
                        href='https://web.diffit.me/contact-us'
                        target='_blank'
                        onClick={() => Mixpanel.track('Header click', { item: 'Contact Us' })}
                        className='inline-flex items-center  border-transparent px-1 pt-1 text-sm text-gray-500 hover:border-gray-300 hover:text-gray-900 border-b-2 border-white hover:border-emerald-300 hover:text-gray-900'>
                        Contact Us
                      </a>
                    </div>
                  )}{' '}
                  {isSignedIn && isLoaded && (
                    <div className='hidden sm:ml-10 sm:flex sm:space-x-8'>
                      <a
                        href='/'
                        onClick={() => Mixpanel.track('Header click', { item: 'Start New' })}
                        className={`inline-flex items-center px-1 pt-1 text-sm border-b-2 border-white hover:border-gray-300 cursor-pointer text-gray-500 hover:text-gray-900`}>
                        Start New
                      </a>
                      <a
                        onClick={() => {
                          Mixpanel.track('Header click', {
                            item: 'My Resources',
                          });
                          navigate('/resources');
                        }}
                        className={`inline-flex items-center px-1 pt-1 text-sm border-b-2 border-white hover:border-gray-300 cursor-pointer text-gray-500 hover:text-gray-900`}>
                        My Resources
                      </a>
                      {enableMyBinderView && (
                        <a
                          role='button'
                          onClick={() => {
                            Mixpanel.track('Header click', {
                              item: 'Activities',
                            });

                            clearActivitiesCache();
                            navigate('/activities');
                            if (isOnActivityBrowserRoot(location.pathname)) {
                              window.location.reload();
                            }
                          }}
                          className={`inline-flex items-center px-1 pt-1 text-sm border-b-2 border-white hover:border-gray-300 cursor-pointer text-gray-500 hover:text-gray-900`}>
                          Activities
                        </a>
                      )}
                    </div>
                  )}
                </div>
                <div className='hidden sm:flex gap-4 self-center'>
                  <SignInButton />
                </div>
                {isSignedIn && isLoaded && (
                  <div className='hidden sm:flex sm:ml-6 sm:items-center'>
                    <div>
                      <Menu as='div' className='relative ml-3'>
                        {({ close }) => (
                          <>
                            <div>
                              <Menu.Button className='flex rounded-full bg-white text-sm text-gray-300 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-emerald-500'>
                                <span className='sr-only'>Open help menu</span>
                                <QuestionMarkCircleIcon className='h-8 w-auto' />
                              </Menu.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              enter='transition ease-out duration-200'
                              enterFrom='transform opacity-0 scale-95'
                              enterTo='transform opacity-100 scale-100'
                              leave='transition ease-in duration-75'
                              leaveFrom='transform opacity-100 scale-100'
                              leaveTo='transform opacity-0 scale-95'>
                              <Menu.Items className='absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      href='https://web.diffit.me/professional-development'
                                      target='_blank'
                                      onClick={() =>
                                        Mixpanel.track('Header click', {
                                          item: 'Learn More',
                                        })
                                      }
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700'
                                      )}>
                                      Learn More
                                    </a>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      href='https://web.diffit.me/contact-us'
                                      target='_blank'
                                      onClick={() =>
                                        Mixpanel.track('Header click', {
                                          item: 'Contact Us',
                                        })
                                      }
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700'
                                      )}>
                                      Contact Us
                                    </a>
                                  )}
                                </Menu.Item>
                              </Menu.Items>
                            </Transition>
                          </>
                        )}
                      </Menu>
                    </div>
                    <div>
                      <Menu as='div' className='relative ml-3'>
                        {({ open, close }) => (
                          <>
                            <div>
                              <Menu.Button
                                onClick={() => {
                                  Mixpanel.track(`${open ? 'Close' : 'Open'} user menu`);
                                }}
                                className='flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2'>
                                <span className='sr-only'>Open user menu</span>
                                <Image
                                  className='h-8 w-8 rounded-full'
                                  src={user.profileImageUrl}
                                  referrerPolicy='no-referrer'
                                  alt=''
                                />
                              </Menu.Button>
                              {showPendingNotification && !open && (
                                <div className='absolute -top-2 -right-2 h-5 w-5 rounded-full bg-red-500 flex items-center justify-center'>
                                  <p className='text-white text-xs text-center'>1</p>
                                </div>
                              )}
                            </div>
                            <Transition
                              as={Fragment}
                              enter='transition ease-out duration-200'
                              enterFrom='transform opacity-0 scale-95'
                              enterTo='transform opacity-100 scale-100'
                              leave='transition ease-in duration-75'
                              leaveFrom='transform opacity-100 scale-100'
                              leaveTo='transform opacity-0 scale-95'>
                              <Menu.Items className='absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white divide-y divide-gray-200 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                                <div>
                                  <div className='flex items-center px-4 text-md font-semibold text-emerald-900'>
                                    <span>{user.fullName}</span>
                                    {isPremiumUser(user, diffitUser) && (
                                      <div className='inline-flex ml-2'>
                                        <PremiumIcon />
                                      </div>
                                    )}
                                  </div>
                                  <div className='px-4 pb-2 text-xs text-gray-500 truncate'>
                                    {user.primaryEmailAddress.emailAddress}
                                  </div>
                                  {diffitUser && !hasActiveSubscription(diffitUser) && (
                                    <div className='px-4 pb-2 text-xs'>
                                      {isFreeTrialUser(user, diffitUser) ? (
                                        <>
                                          <div className='italic text-gray-700 pb-0.5'>
                                            {`You have ${
                                              daysLeftInTrial(user, diffitUser) === 0
                                                ? '<1 day'
                                                : daysLeftInTrial(user, diffitUser) === 1
                                                  ? '1 day'
                                                  : daysLeftInTrial(user, diffitUser) + ' days'
                                            } left in your premium trial.`}
                                          </div>
                                          {shouldShowTrialExtensionOption(diffitUser) ? (
                                            <button
                                              onClick={(e) => {
                                                close();
                                                setTimeout(() => {
                                                  Mixpanel.track(
                                                    'Opened trial_extension modal in Avatar Menu',
                                                    {
                                                      hasExistingTrial: true,
                                                      daysLeftInTrial: daysLeftInTrial(
                                                        user,
                                                        diffitUser
                                                      ),
                                                    }
                                                  );
                                                  // small timeout to avoid them being on the screen at the same time
                                                  setShowUpgradeModal(true);
                                                }, 100);
                                              }}
                                              className={`text-left text-emerald-500`}>
                                              Click here to extend your trial
                                            </button>
                                          ) : (
                                            <>
                                              {!switchTrialExtensionFlowToSurvey &&
                                                userHasEnteredSchoolRecord(diffitUser) && (
                                                  <div>
                                                    <button
                                                      onClick={async () => {
                                                        Mixpanel.track(
                                                          'Get (Existing) School Share Code in Avatar Menu'
                                                        );
                                                        const response = await getUsersSchoolCode(
                                                          getSchoolFromUser(diffitUser).id
                                                        );
                                                        setExistingPromoCode(
                                                          response.data.promo_code.code
                                                        );
                                                        close();
                                                      }}
                                                      className={`text-emerald-500 hover:underline inline`}>
                                                      Click here
                                                    </button>{' '}
                                                    to see your share code for teachers at{' '}
                                                    {diffitUser.school_records[0].name}.
                                                  </div>
                                                )}
                                              {switchTrialExtensionFlowToSurvey &&
                                                userHasCompletedTrialExtensionSurvey(
                                                  diffitUser
                                                ) && (
                                                  <div>
                                                    <button
                                                      onClick={async () => {
                                                        Mixpanel.track(
                                                          'Get (Existing) Share Code in Avatar Menu'
                                                        );
                                                        const response = await getUsersShareCode();
                                                        setExistingPromoCode(
                                                          response.data.promo_code.code
                                                        );
                                                        close();
                                                      }}
                                                      className={`text-emerald-500 hover:underline inline`}>
                                                      Click here
                                                    </button>{' '}
                                                    to see your share code
                                                  </div>
                                                )}
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <span>
                                            You are using Diffit’s free version.{' '}
                                            {shouldShowTrialExtensionOption(diffitUser) ? (
                                              <>
                                                <button
                                                  onClick={(e) => {
                                                    Mixpanel.track(
                                                      'Opened extend_with_school modal in Avatar Menu',
                                                      {
                                                        hasExistingTrial: false,
                                                      }
                                                    );
                                                    close();
                                                    setTimeout(() => {
                                                      setShowUpgradeModal(true);
                                                    }, 100);
                                                  }}
                                                  className={`text-left text-emerald-500`}>
                                                  Click here to get 4 more months of premium access,
                                                  free!
                                                </button>
                                              </>
                                            ) : (
                                              <a
                                                href='https://web.diffit.me/schools'
                                                target='_blank'
                                                className='text-purple-600 hover:text-purple-500'
                                                onClick={() =>
                                                  Mixpanel.track('Learn about Premium Clicked', {
                                                    isFreeTrialUser: isFreeTrialUser(
                                                      user,
                                                      diffitUser
                                                    ),
                                                    daysLeftInTrial: daysLeftInTrial(
                                                      user,
                                                      diffitUser
                                                    ),
                                                  })
                                                }>
                                                Learn about Premium!
                                              </a>
                                            )}
                                          </span>
                                        </>
                                      )}
                                    </div>
                                  )}
                                  {!!diffitUser &&
                                    diffitUser.active_orgs?.length >= 1 &&
                                    selectedOrg && (
                                      <div className='px-4 pb-2 text-lg text-emerald-500'>
                                        <a
                                          className={`${
                                            selectedOrg.role === 'Admin'
                                              ? 'hover:text-emerald-400'
                                              : 'pointer-events-none'
                                          }`}
                                          href={`${selectedOrg.role === 'Admin' ? `/org/${selectedOrg.org_id}` : ''}`}>
                                          {selectedOrg.org.name}
                                        </a>{' '}
                                      </div>
                                    )}
                                </div>
                                {selectedOrg && selectedOrg.role === 'Admin' && (
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        onClick={() => {
                                          Mixpanel.track('Admin Dashboard');
                                          navigate('/org/' + selectedOrg.org.id);
                                        }}
                                        className={classNames(
                                          active ? 'bg-gray-100' : '',
                                          'cursor-pointer block px-4 py-2 text-sm text-gray-700'
                                        )}>
                                        Manage Users
                                      </a>
                                    )}
                                  </Menu.Item>
                                )}
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      onClick={() => {
                                        Mixpanel.track('My Resources');
                                        navigate('/resources');
                                      }}
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'cursor-pointer block pl-4 pr-4 py-2 text-sm text-gray-700'
                                      )}>
                                      My Resources
                                    </a>
                                  )}
                                </Menu.Item>

                                {enableMixMatchPreviewUi && (
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        onClick={() => {
                                          Mixpanel.track('My Templates (User)');
                                          navigate('/templates');
                                        }}
                                        className={classNames(
                                          active ? 'bg-gray-100' : '',
                                          'cursor-pointer block pl-4 pr-4 py-2 text-sm text-gray-700'
                                        )}>
                                        My Templates
                                      </a>
                                    )}
                                  </Menu.Item>
                                )}
                                {!!diffitUser &&
                                  (isActiveIndividualSubscription(diffitUser) ||
                                    diffitUser.stripe_needs_attention) && (
                                    <Menu.Item>
                                      {({ active }) => (
                                        <a
                                          onClick={() => {
                                            Mixpanel.track('Manage Subscription');
                                            navigate('/subscription');
                                          }}
                                          className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'flex cursor-pointer block px-4 py-2 text-sm text-gray-700'
                                          )}>
                                          Manage Subscription{' '}
                                          {diffitUser.stripe_needs_attention && (
                                            <ExclamationCircleIcon className='h-5 w-5 self-center self-justify-center text-red-500 inline' />
                                          )}
                                        </a>
                                      )}
                                    </Menu.Item>
                                  )}
                                {!!diffitUser && diffitUser.is_diffit_admin && (
                                  <>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <a
                                          onClick={() => {
                                            navigate('/admin');
                                          }}
                                          className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'cursor-pointer block pl-4 pr-4 py-2 text-sm text-gray-700'
                                          )}>
                                          Diffit Admin
                                        </a>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <a
                                          onClick={() => {
                                            Mixpanel.track('Diffit Templates');
                                            navigate('/admin/templates');
                                          }}
                                          className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'cursor-pointer block pl-4 pr-4 py-2 text-sm text-gray-700'
                                          )}>
                                          Diffit Templates
                                        </a>
                                      )}
                                    </Menu.Item>
                                  </>
                                )}
                                {newUserOnboardingExperience &&
                                  returningUserOnboardingExperience &&
                                  !!diffitUser && (
                                    <Menu.Item>
                                      {({ active }) => (
                                        <div className='flex flex-row w-full'>
                                          <a
                                            onClick={() => {
                                              setShowOnboardingWizard(true);
                                              Mixpanel.track('Avatar Menu - Selected Profile');
                                            }}
                                            className={classNames(
                                              active ? 'bg-gray-100' : '',
                                              'cursor-pointer block px-4 py-2 text-sm text-gray-700 flex flex-row w-full'
                                            )}>
                                            Profile
                                            {showPendingNotification && open && (
                                              <div className='flex items-center ml-2'>
                                                <div className='h-5 w-5 rounded-full bg-red-500 flex items-center justify-center'>
                                                  <p className='text-white text-xs text-center'>
                                                    1
                                                  </p>
                                                </div>
                                              </div>
                                            )}
                                          </a>
                                        </div>
                                      )}
                                    </Menu.Item>
                                  )}
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      onClick={handleSignOut}
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'cursor-pointer block px-4 py-2 text-sm text-gray-700'
                                      )}>
                                      Sign out
                                    </a>
                                  )}
                                </Menu.Item>
                              </Menu.Items>
                            </Transition>
                          </>
                        )}
                      </Menu>
                    </div>
                  </div>
                )}
                <div className='-mr-2 flex items-center sm:hidden'>
                  {/* Mobile menu button */}
                  <Disclosure.Button className='inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-emerald-500'>
                    <span className='sr-only'>Open main menu</span>
                    {open ? (
                      <XMarkIcon className='block h-6 w-6' aria-hidden='true' />
                    ) : (
                      <Bars3Icon className='block h-6 w-6' aria-hidden='true' />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className='sm:hidden'>
              <div className='space-y-1 pt-2 pb-3'>
                {/* Current: "bg-emerald-50 border-emerald-500 text-emerald-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                <Disclosure.Button
                  onClick={() => {
                    Mixpanel.track('Header click', { item: 'Start New' });
                    navigate('/');
                  }}
                  className={` ${isOnAnActivityPage ? 'text-gray-500' : 'border-emerald-500 bg-emerald-50 text-emerald-700'} w-full text-left block border-l-4 py-2 pl-3 pr-4 text-base font-medium `}>
                  Start New
                </Disclosure.Button>
                {enableMyBinderView && (
                  <Disclosure.Button
                    onClick={() => {
                      Mixpanel.track('Header click', { item: 'Start New' });
                      navigate('/activities');
                    }}
                    className={`${isOnAnActivityPage ? 'border-emerald-500 bg-emerald-50 text-emerald-700' : 'text-gray-500'} w-full text-left block border-l-4  py-2 pl-3 pr-4 text-base font-medium `}>
                    Browse Activities
                  </Disclosure.Button>
                )}
                <Disclosure.Button
                  as='a'
                  href='https://web.diffit.me/professional-development'
                  target='_blank'
                  onClick={() => Mixpanel.track('Header click', { item: 'Learn More' })}
                  className='block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700'>
                  Learn More
                </Disclosure.Button>
                <Disclosure.Button
                  as='a'
                  href='https://web.diffit.me/contact-us'
                  target='_blank'
                  onClick={() => Mixpanel.track('Header click', { item: 'Contact Us' })}
                  className='block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700'>
                  Contact Us
                </Disclosure.Button>
                <div className='flex sm:hidden mx-3 self-center'>
                  <SignInButton />
                </div>
              </div>
              {isSignedIn && (
                <div className='border-t border-gray-200 pt-4 pb-3'>
                  <div className='flex items-center px-4'>
                    <div className='flex-shrink-0'>
                      <Image
                        className='h-10 w-10 rounded-full'
                        src={user.profileImageUrl}
                        referrerPolicy='no-referrer'
                        alt=''
                      />
                    </div>
                    <div className='ml-3'>
                      <div className='text-base font-medium text-emerald-900'>{user.fullName}</div>
                      <div className='text-sm font-medium text-gray-500'>
                        {user.primaryEmailAddress.emailAddress}
                      </div>
                    </div>
                  </div>
                  <div className='mt-3 space-y-1'>
                    <Disclosure.Button
                      as='a'
                      onClick={handleSignOut}
                      className='block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800'>
                      Sign out
                    </Disclosure.Button>
                    <Disclosure.Button
                      as='a'
                      onClick={() => {
                        Mixpanel.track('My Resources');
                        navigate('/resources');
                      }}
                      className='block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 cursor-pointer'>
                      My Resources
                    </Disclosure.Button>
                    {!!diffitUser &&
                      (isActiveIndividualSubscription(diffitUser) ||
                        diffitUser.stripe_needs_attention) && (
                        <Disclosure.Button
                          as='a'
                          onClick={() => {
                            Mixpanel.track('Manage Subscription');
                            navigate('/subscription');
                          }}
                          className='block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800'>
                          Manage Subscription
                        </Disclosure.Button>
                      )}
                  </div>
                </div>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <Notices />
    </>
  );
}
