import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { addUser, markUserNuxAsSeen } from '../services/UserService';
import { Mixpanel } from '../services/Mixpanel';
import { getCurrentUser } from '../services/UserService';
import { DiffitUserContext } from '../contexts/DiffitUserContext';
import Header, { isOnActivityPath } from '../components/Header';
import SignInSuccessModal from '../components/SignInSuccessModal';
import WelcomeToPremiumModal from '../components/WelcomeToPremiumModal';
import { useAuth, useUser } from '@clerk/clerk-react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { hasActiveSubscription, isFreeTrialUser } from '../utils/isPremiumUser';
import { v4 as uuidv4 } from 'uuid';
import { clearActivitiesCache } from './Activities/activities';
import { OnboardingWizard } from '../components/OnboardingWizard';

const getOrSetDiffitSessionId = () => {
  let sessionId = localStorage.getItem('diffitSessionId');
  if (!sessionId) {
    sessionId = uuidv4();
    localStorage.setItem('diffitSessionId', sessionId);
  }
  return sessionId;
};

export default function Base() {
  const ldClient = useLDClient();
  const location = useLocation();
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const [showOnboardingWizard, setShowOnboardingWizard] = useState(false);

  const { signOut, getToken, isSignedIn, isLoaded } = useAuth();
  const { user } = useUser();
  const [query, setQuery] = useSearchParams();
  const [diffitUser, setDiffitUser] = useState();
  const [selectedOrg, setSelectedOrg] = useState();
  const [isLoadingDiffitUser, setIsLoadingDiffitUser] = useState(false);
  const diffitSessionId = getOrSetDiffitSessionId();

  const isPremiumUser = hasActiveSubscription(diffitUser) || isFreeTrialUser(user, diffitUser);

  const showNuxModal = (diffitUser) => {
    let seenNux = diffitUser.seen_nux_json ? JSON.parse(diffitUser.seen_nux_json) : {};
    const isOnRedeemPath = window.location.pathname === '/redeem';
    
    // First things first, show the new onboarding wizard if enabled.
    if (isOnRedeemPath) {
      setShowOnboardingWizard(false);
    } else if (seenNux['new_user_required_onboarding'] && !seenNux['user_completed_onboarding']) {
      // First things first, show
      setShowOnboardingWizard(true);
    } else if (!seenNux['welcome_to_premium'] && hasActiveSubscription(diffitUser)) {
      // Otherwise if you're a premium user, show the premium modal.
      setShowPremiumModal(true);
      markUserNuxAsSeen(diffitUser.id, ['welcome_to_diffit', 'welcome_to_premium']);
      // If the onboarding flow is not enabled, show the old new user modal.
    } else if (!seenNux['new_user_required_onboarding'] && !seenNux['welcome_to_diffit']) {
      setShowNewUserModal(true);
      markUserNuxAsSeen(diffitUser.id, ['welcome_to_diffit']);
    }
  };

  const refreshDiffitUser = () => {
    getCurrentUser()
      .then((response) => {
        setUpDiffitUser(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setUpDiffitUser = (response) => {
    setDiffitUser(response.data);
    if (response.data.subjects) {
      Mixpanel.people.set({
        subjects: response.data.subjects.map((subject) => subject.subject),
      });
    }
    if (response.data.grade_levels) {
      Mixpanel.people.set({
        grade_levels: response.data.grade_levels.map((gradeLevel) => gradeLevel.grade_level),
      });
    }
    // Check local storage for selected org
    let storedOrg = JSON.parse(localStorage.getItem('selectedOrg'));
    // Check to make sure the ID is in the active_orgs array
    // Filter
    let previouslySelectedOrg = response.data.active_orgs.find(
      (org) => org.org_id === storedOrg?.org_id
    );
    if (storedOrg && !previouslySelectedOrg) {
      localStorage.removeItem('selectedOrg');
    }
    // Set selected org to an org you are an admin of, otherwise the stored org if it exists, otherwise set to first org in array
    let adminOrg = response.data.active_orgs.find((org) => org.role === 'Admin');
    setSelectedOrg(
      adminOrg
        ? adminOrg
        : previouslySelectedOrg
          ? previouslySelectedOrg
          : response.data.active_orgs[0]
    );
    // Save either stored org or first org in array to local storage, if it exists
    if (previouslySelectedOrg) {
      localStorage.setItem('selectedOrg', JSON.stringify(previouslySelectedOrg));
    } else if (response.data.active_orgs[0]) {
      localStorage.setItem('selectedOrg', JSON.stringify(response.data.active_orgs[0]));
    }

    showNuxModal(response.data);
  };

  useEffect(() => {
    // First check if the user has just logged in. The "success" query param will be set.
    const success = query.get('success');
    const invited = query.get('invited');
    const emailId = query.get('email_id');
    const signout = query.get('signout');
    const share = query.get('share');
    if (share) {
      if (isLoaded) {
        query.delete('share');
        setQuery(query);
        return;
      }
    }
    if (emailId) {
      Mixpanel.track('Clicked Email Link', { emailId: emailId });
      query.delete('email_id');
      setQuery(query);
      return;
    }
    if (signout) {
      signOut();
      query.delete('signout');
      setQuery(query);
      return;
    }
    // We don't do anything with the "invited" query param right now
    if (invited) {
      query.delete('invited');
      setQuery(query);
      return;
    }
    if (success && isSignedIn && isLoaded && !isLoadingDiffitUser) {
      setIsLoadingDiffitUser(true);
      // If the user has just logged in, then let's POST them to the backend.
      const current_url = window.location.pathname;
      // The packetId should be extracted only if the current_url is /packet/:packetId
      const packetId = current_url.split('/')[1] === 'packet' ? current_url.split('/')[2] : null;
      getToken({ template: 'userinfo', skipCache: true })
        .then((token) => {
          addUser(packetId, token)
            .then((response) => {
              let loginResponse = response.data;
              Mixpanel.identify(loginResponse.id);
              Mixpanel.people.set({
                user_id: loginResponse.id,
                clerk_id: loginResponse.clerk_id,
                name: loginResponse.name,
                given_name: loginResponse.given_name,
                family_name: loginResponse.family_name,
                email: loginResponse.email,
              });
              Mixpanel.track('Successful Login');
              setUpDiffitUser(response);
              query.delete('success');
              setQuery(query);
            })
            .catch((error) => {
              signOut();
              setDiffitUser(null);
              window.location.reload();
              console.log(error);
            });
        })
        .catch((error) => {
          signOut();
          setDiffitUser(null);
          window.location.reload();
          console.log(error);
        })
        .finally(() => {
          setIsLoadingDiffitUser(false);
        });
      // If the user has not just logged in, then let's get their user info from the backend.
    } else if (isLoaded && isSignedIn && !isLoadingDiffitUser) {
      setIsLoadingDiffitUser(true);
      getCurrentUser()
        .then((response) => {
          setUpDiffitUser(response);
        })
        .catch((error) => {
          setDiffitUser(null);
          signOut();
          window.location.reload();
          console.log(error);
        })
        .finally(() => {
          setIsLoadingDiffitUser(false);
        });
    } else if (isLoaded && !isSignedIn) {
      setDiffitUser(null);
    }
  }, [query, isSignedIn, isLoaded]);

  useEffect(() => {
    if (!isLoaded || !ldClient) return;
    if (isSignedIn && !diffitUser) return; // Wait for the user to get logged in

    if (isSignedIn) {
      // Phone home to LaunchDarkly
      ldClient?.identify({
        kind: 'user',
        key: user.id,
        email: user.primaryEmailAddress?.emailAddress,
        name: user.fullName,
        sessionId: diffitSessionId,
        isActiveIndividualSubscription: !!diffitUser.is_active_individual_subscription,
        isInPremiumOrg: !!diffitUser.is_in_premium_org,
        isFreeTrial: !!diffitUser.is_free_trial,
        isPremium: !!diffitUser.is_premium,
        createdAt: new Date(diffitUser.created_at),
      });
    } else {
      ldClient?.identify({
        kind: 'user',
        anonymous: true,
        sessionId: diffitSessionId,
      });
    }
  }, [isSignedIn, ldClient, isLoaded, diffitUser, user]);

  useEffect(() => {
    Mixpanel.track('Page Viewed', { path: location.pathname });
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname.split('/')[1] !== 'packet') {
      // Clear session storage for preview if route is not /packet/:packetId
      sessionStorage.removeItem('isInPreview');
      sessionStorage.removeItem('templatePreviouslyPreviewed');
      sessionStorage.removeItem('template_id');
    }
    if (!isOnActivityPath(location.pathname)) {
      // Clear session storage for activities if navigating away from activities browsing view
      clearActivitiesCache();
    }
  }, [location.pathname]);

  return (
    <DiffitUserContext.Provider
      value={{
        diffitUser,
        setDiffitUser,
        selectedOrg,
        setSelectedOrg,
        isPremiumUser,
        isSignedIn,
        refreshDiffitUser,
        showOnboardingWizard,
        isLoadingDiffitUser,
      }}>
      <Header
        setShowOnboardingWizard={setShowOnboardingWizard}
        showOnboardingWizard={showOnboardingWizard}
      />
      <Outlet />
      <SignInSuccessModal open={showNewUserModal} setOpen={setShowNewUserModal} />
      <WelcomeToPremiumModal
        open={showPremiumModal}
        setOpen={setShowPremiumModal}
        schoolName={selectedOrg?.org.name}
      />
      {showOnboardingWizard && (
        <OnboardingWizard
          showWizard={showOnboardingWizard}
          setShowWizard={() => {
            setShowOnboardingWizard(false);
          }}
        />
      )}
    </DiffitUserContext.Provider>
  );
}
