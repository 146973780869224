import { HeartIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useCallback, useContext, useEffect, useLayoutEffect, useState, useRef } from 'react';
import { BasicsIcon } from './icons/BasicsIcon';
import { SummarizeLearningIcon } from './icons/SummarizeLearningIcon';
import { VocabularyIcon } from './icons/VocabularyIcon';
import { ReadingStrategiesIcon } from './icons/ReadingStrategiesIcon';
import { WritingStrategiesIcon } from './icons/WritingStrategiesIcon';
import { TextAnalysisIcon } from './icons/TextAnalysisIcon';
import { CollaborationIcon } from './icons/CollaborationIcon';
import { CriticalThinkingIcon } from './icons/CriticalThinkingIcon';
import SlidesBgPng from '../../assets/slides-bg.png';
import WorkbookBgPng from '../../assets/workbook-bg.png';

import { MyActivitiesIcon } from './icons/MyActivitiesIcon';
import {
  addToFavorites,
  getActivities,
  removeFromFavorites,
  templateConstants,
} from '../../services/TemplateService';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getPacket } from '../../services/PacketService';
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/20/solid';
import { Select } from '../../components/library/select';
import { debounce, isEmpty } from 'lodash';
import { useAuth, useUser } from '@clerk/clerk-react';
import { DiffitUserContext } from '../../contexts/DiffitUserContext';
import { isPremiumUser } from '../../utils/isPremiumUser';
import Image from '../../components/Image';
import { Mixpanel } from '../../services/Mixpanel';
import { PARTNER_COLLECTION_CATEGORIES } from './partnerCollections';
import { Button } from '../../components/library/button';
import { useSticky } from '../../hooks/useSticky';
import { isJeopardyTemplate } from '../../utils/templates';
import { FavoritesIcon } from './icons/FavoritesIcon';

const ACTIVITIES_PAGE_SIZE = 9;

export const clearActivitiesCache = () => {
  sessionStorage.removeItem('cachedActivityTemplates');
  sessionStorage.removeItem('activitySessionCache');
};

const getCachedTemplatesFromSessionStorage = () => {
  const cachedTemplates = sessionStorage.getItem('cachedActivityTemplates');
  if (cachedTemplates) {
    try {
      return JSON.parse(cachedTemplates);
    } catch (e) {
      console.error('Error parsing cached templates from session storage', e);
    }
  }
  return {};
};

const scrollToMainTitle = () => {
  const mainTitle = document.getElementById('main-title');
  if (mainTitle) {
    mainTitle.scrollIntoView({
      block: 'start',
    });
  }
};

export const Activities = () => {
  const { packetId, activityId } = useParams();
  const [packet, setPacket] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState();
  const [query, setQuery] = useSearchParams();
  const [showMyActivities, setShowMyActivities] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTemplates = async () => {
      const response = await getActivities({ page_size: ACTIVITIES_PAGE_SIZE });
      setTemplatesAndSessionStorage(response.data.results);

      // After initial load of activities, scroll to the top of the page.
      window.scrollTo(0, 0);
    };

    if (isEmpty(templates) && !query.get('category') && !query.get('search')) {
      fetchTemplates();
    }

    if (query.get('category')) {
      setSelectedCategory(query.get('category'));
    }
    if (query.get('search')) {
      setSearchTerm(query.get('search'));
      debouncedHandleSearch(query.get('search'));
    }
  }, [query]);

  useEffect(() => {
    if (selectedCategory) {
      query.set('category', selectedCategory);
      setQuery(query, { replace: true });
    } else {
      if (query.get('category')) {
        query.delete('category');
        setQuery(query);
      }
    }
  }, [selectedCategory]);

  const [templates, _setTemplates] = useState(getCachedTemplatesFromSessionStorage());
  const setTemplatesAndSessionStorage = (newTemplates) => {
    for (const category in newTemplates) {
      newTemplates[category].activities.forEach((activity) => {
        try {
          activity.formats = JSON.parse(activity.formats);
        } catch (e) {
          // sometimes this is already parsed
        }
      });
    }
    _setTemplates(newTemplates);
    if (!showMyActivities) {
      // if 'show my activiites' is ever set to true, don't allow it to get set it back to false
      const hasActivities = newTemplates['My Custom Templates']?.activities?.length > 0;
      if (hasActivities) {
        setShowMyActivities(true);
      }
    }

    sessionStorage.setItem('cachedActivityTemplates', JSON.stringify(newTemplates));
  };
  const [selectedFormats, setSelectedFormats] = useState(templateConstants.FORMAT_NAMES);

  useLayoutEffect(() => {
    const activitySessionCache = sessionStorage.getItem('activitySessionCache');
    if (!activitySessionCache) {
      return;
    }
    let parsedCache;
    try {
      parsedCache = JSON.parse(activitySessionCache);
    } catch (e) {
      console.error('Error parsing activity session cache', e, parsedCache);
    }
    if (parsedCache) {
      setSearchTerm(parsedCache.searchTerm);
      if (!query.get('category')) {
        setSelectedCategory(parsedCache.selectedCategory);
      }
      setSelectedFormats(parsedCache.selectedFormats);
    }
    setShowMyActivities(parsedCache.showMyActivities);
    sessionStorage.removeItem('activitySessionCache');
  }, []);

  useEffect(() => {
    const fetchPacket = async () => {
      let response = await getPacket(packetId);
      let packet = response.data.packet;
      setPacket(packet);
    };
    if (packetId) {
      fetchPacket();
    }
  }, []);

  let packetHasVocab = false;
  if (packet) {
    const vocabJson = JSON.parse(packet.vocab);

    packetHasVocab = !!vocabJson?.vocab?.[0]?.images;
  }
  const debouncedHandleSearch = useCallback(
    debounce(async (newTerm) => {
      if (newTerm === '') {
        const response = await getActivities({ page_size: ACTIVITIES_PAGE_SIZE });
        setTemplatesAndSessionStorage(response.data.results);
      } else {
        Mixpanel.track('Searched Activities', {
          searchTerm: newTerm,
          selectedCategory,
          selectedFormats,
        });
        const response = await getActivities({ page_size: ACTIVITIES_PAGE_SIZE, search: newTerm });
        setTemplatesAndSessionStorage(response.data.results);
      }
    }, 300),
    []
  );

  return (
    <div
      id='outermost-container'
      className='p-8 sm:px-12 pt-0 xl:p-16 xl:px-36 xl:pt-0 flex flex-col min-h-[90vh]'>
      <div id='container' className='flex flex-col mt-4 sm:mt-0 flex-1 h-full'>
        {/* This container must be 'relative' so that the scrollLeft and scrollRight buttons get positioned correctly */}
        <div id='images-and-search-container' className='relative'>
          <div id='image-and-cta-visual' className='flex items-center justify-center'>
            {/* the mb values should match the padding of the 'outermost-container' element to keep consistent spacing  */}
            <div className='flex flex-col sm:flex-row sm:items-center sm:justify-between w-full py-6'>
              {packet?.title ? (
                <div className='flex items-center space-x-1'>
                  <button
                    onClick={() => navigate(`/packet/${packetId}`)}
                    className='text-emerald-600 text-lg font-medium truncate max-w-[248px] hover:text-emerald-500 focus:outline-none'>
                    {packet?.title}
                  </button>
                  <ChevronRightIcon className='w-5 h-5 text-gray-500' />
                  <span className='text-gray-900 text-lg font-medium'>Activities</span>
                </div>
              ) : (
                <div className='flex flex-col'>
                  <h1 className='text-emerald-600 font-bold text-xl sm:text-2xl'>
                    Student-Ready Activities
                  </h1>
                  <p className='text-gray-500 mt-1 text-sm'>
                    Pick a template, add Diffit content, and get classroom-ready in minutes.
                  </p>
                </div>
              )}
            </div>
          </div>
          <CategoryButtonRow
            templates={templates}
            selectedOption={selectedCategory}
            setSelectedOption={async (option) => {
              setSearchTerm('');
              setSelectedCategory(option);
              const response = await getActivities({ page_size: ACTIVITIES_PAGE_SIZE });
              setTemplatesAndSessionStorage(response.data.results);
            }}
            showMyActivities={showMyActivities}
          />
        </div>
        <div id='main-contents'>
          <MainContents
            packetHasVocab={packetHasVocab}
            setSelectedCategory={setSelectedCategory}
            selectedCategory={selectedCategory}
            templates={templates}
            selectedFormats={selectedFormats}
            setSelectedFormats={setSelectedFormats}
            searchTerm={searchTerm}
            setSearchTerm={(newTerm) => {
              // set the search term immediately so the ui updates
              setSearchTerm(newTerm);
              setSelectedCategory(undefined);

              // but debounce the actual search
              debouncedHandleSearch(newTerm);
            }}
            showMyActivities={showMyActivities}
            onClickViewMore={async (category) => {
              Mixpanel.track('Clicked View More', { category });
              const curr = templates[category];
              const page = curr.page_number + 1;
              const page_size = ACTIVITIES_PAGE_SIZE;
              const newTemplates = { ...templates };
              const response = await getActivities({ page, page_size, category });
              newTemplates[category].activities = [
                ...newTemplates[category].activities,
                ...response.data.results.activities,
              ];
              newTemplates[category].page_number = page;
              setTemplatesAndSessionStorage(newTemplates);
            }}
            onToggleFavorite={async (template) => {
              Mixpanel.track('Toggled favorite', {
                templateId: template.id,
                templateTitle: template.title,
                didFavorite: !template.is_favorite,
              });
              if (template.is_favorite) {
                await removeFromFavorites(template.id);
                template.is_favorite = false;
              } else {
                await addToFavorites(template.id);
                template.is_favorite = true;
              }
              // now update the template in local state
              // one template may be in multiple categories, so we need to look through all of them
              const newTemplates = { ...templates };
              for (const category in newTemplates) {
                newTemplates[category].activities.forEach((activity) => {
                  if (activity.id === template.id) {
                    activity = template;
                  }
                });
              }
              // finally update the "Favorites" category to include or exclude the template
              const existingFavorites = newTemplates['Favorites']?.activities || [];
              if (template.is_favorite) {
                existingFavorites.push(template);
              } else {
                const newFavorites = existingFavorites.filter(
                  (activity) => activity.id !== template.id
                );
                if (!newTemplates['Favorites']) {
                  newTemplates['Favorites'] = { activities: [] };
                }
                newTemplates['Favorites'].activities = newFavorites;
              }

              setTemplatesAndSessionStorage(newTemplates);
            }}
          />
        </div>
      </div>
    </div>
  );
};

const OPTION_TO_ICON = {
  Favorites: FavoritesIcon,
  'My Custom Templates': MyActivitiesIcon,
  Basics: BasicsIcon,
  'Summarize Learning': SummarizeLearningIcon,
  Vocabulary: VocabularyIcon,
  'Reading Strategies': ReadingStrategiesIcon,
  'Writing Strategies': WritingStrategiesIcon,
  'Text Analysis': TextAnalysisIcon,
  Collaboration: CollaborationIcon,
  'Critical Thinking': CriticalThinkingIcon,
};

const CategoryButtonRow = ({
  templates = [],
  selectedOption,
  setSelectedOption,
  showMyActivities,
}) => {
  const scrollContainerRef = useRef(null);
  const [showLeftChevron, setShowLeftChevron] = useState(false);
  const [showRightChevron, setShowRightChevron] = useState(false);

  // Function to handle scrolling when the Chevron is clicked
  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 300, // Adjust scroll amount as needed
        behavior: 'smooth',
      });
    }
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -300, // Adjust scroll amount as needed
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const handleScroll = () => {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainer;
      setShowLeftChevron(scrollLeft > 0);
      setShowRightChevron(Math.ceil(scrollLeft + clientWidth) < scrollWidth);
    };

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
      handleScroll(); // Initialize the chevron visibility
    }

    window.addEventListener('resize', handleScroll);

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  return (
    <div
      id='category-buttons-container'
      ref={scrollContainerRef}
      className='w-full overflow-x-auto flex items-center lg:justify-around gap-x-6 border-b border-t border-gray-200 py-0'>
      {showLeftChevron && (
        <button
          onClick={scrollLeft}
          className='absolute left-2 bg-white p-1.5 rounded-lg shadow-md hover:bg-gray-100 focus:outline-none border border-gray-300 z-30'
          aria-label='Scroll Left'>
          <ChevronLeftIcon className='w-5 h-5 text-emerald-600' />
        </button>
      )}
      {Object.keys(OPTION_TO_ICON)
        .map((option) => {
          const Icon = OPTION_TO_ICON[option];
          return (
            <div
              key={option}
              className={`flex flex-col items-center min-w-[75px] relative flex-shrink-0 border-b-2 ${
                selectedOption === option
                  ? 'text-emerald-600 border-emerald-600'
                  : 'text-gray-600 border-transparent'
              }`}>
              <button
                className={`group flex flex-col items-center gap-y-1 text-xs md:text-sm pt-6 hover:text-emerald-600`}
                onClick={() => {
                  if (selectedOption === option) {
                    Mixpanel.track('Clicked active Category Button to unset filter');
                    setSelectedOption(undefined);
                  } else {
                    Mixpanel.track('Clicked Category Button', {
                      category: option,
                    });
                    setSelectedOption(option);
                  }
                }}>
                <Icon
                  className={`w-6 h-6 mb-1 group-hover:fill-emerald-600 hover:text-emerald-600 ${
                    selectedOption === option
                      ? 'fill-emerald-600 text-emerald-600'
                      : 'fill-gray-500'
                  }`}
                />
                <span
                  className={`relative pb-6 text-xs whitespace-nowrap hover:text-emerald-600 ${
                    selectedOption === option ? 'text-emerald-600' : ''
                  }`}
                  style={{
                    display: 'inline-block',
                    position: 'relative',
                  }}>
                  {option === 'My Custom Templates' ? 'Custom' : option}
                </span>
              </button>
            </div>
          );
        })}

      {showRightChevron && (
        <button
          onClick={scrollRight}
          className='absolute right-2 bg-white p-1.5 rounded-lg shadow-md hover:bg-gray-100 focus:outline-none border border-gray-300 z-30'
          aria-label='Scroll Right'>
          <ChevronRightIcon className='w-5 h-5 text-emerald-600' />
        </button>
      )}
    </div>
  );
};

const MainContents = ({
  selectedCategory,
  setSelectedCategory,
  templates,
  selectedFormats,
  setSelectedFormats,
  searchTerm,
  setSearchTerm,
  onClickViewMore,
  onToggleFavorite,
  showMyActivities,
  packetHasVocab,
}) => {
  const { packetId } = useParams();
  const navigate = useNavigate();
  let orderedCategories = [];
  if (selectedCategory === undefined) {
    for (const category in templates) {
      if (
        typeof (templates[category].order === 'number') &&
        templates[category].activities.length > 0
      ) {
        orderedCategories.push(category);
      }
    }
    // sort
    orderedCategories.sort((a, b) => {
      return templates[a].order - templates[b].order;
    });

    // if we are browsing activities for a particular packet and that packet has vocab
    // images, move "Vocabulary With Images" to the top of the list
    if (packetHasVocab) {
      orderedCategories = orderedCategories.filter(
        (category) => category !== 'Vocabulary With Images'
      );
      orderedCategories.unshift('Vocabulary With Images');
    }
  } else {
    orderedCategories.push(selectedCategory);
  }

  const onClickActivity = (template) => {
    Mixpanel.track('Activity clicked', { templateId: template.id, name: template.title });
    let templateUrl = `/activity/${template.id}`;
    if (packetId) {
      templateUrl += `/${packetId}`;
    }
    sessionStorage.setItem(
      'activitySessionCache',
      JSON.stringify({
        showMyActivities: showMyActivities,
        searchTerm,
        selectedCategory,
        selectedFormats,
      })
    );
    navigate(templateUrl);
  };

  let templatesToRender = [];
  if (selectedFormats.length !== templateConstants.FORMAT_NAMES.length) {
    for (const category in templates) {
      if (templates[category].activities.length > 0) {
        const newActivities = templates[category].activities.filter((activity) => {
          // check if any of activity.formats are in selectedFormats
          const activityFormats = activity.formats || [];
          for (const format of selectedFormats) {
            if (activityFormats.includes(templateConstants.FORMAT_NAME_TO_TYPE[format])) {
              return true;
            }
          }
        });
        templatesToRender[category] = { ...templates[category], activities: newActivities };
      }
    }
  } else {
    templatesToRender = templates;
  }

  const noResults =
    Object.keys(templatesToRender).every(
      (category) => templatesToRender[category].activities.length === 0
    );

  return (
    <div className='mt-8'>
      <HeaderRow
        selectedFormats={selectedFormats}
        setSelectedFormats={setSelectedFormats}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        setSelectedCategory={setSelectedCategory}
        selectedCategory={selectedCategory}
        showMyActivities={showMyActivities}
        templates={templates}
      />
      {noResults ? (
        <div className='mt-14 flex-grow h-full flex justify-center'>No Results</div>
      ) : (
        orderedCategories.map((category, index) => {
          if (templatesToRender[category]?.activities?.length === 0) {
            return null;
          }
          return (
            <div key={category}>
              <ActivitySection
                key={category}
                category={category}
                selectedCategory={selectedCategory}
                templates={templatesToRender[category]}
                numLoadedInCategory={templates[category]?.activities?.length}
                onClickViewMore={() => {
                  onClickViewMore(category);
                }}
                onClickActivity={onClickActivity}
                onToggleFavorite={onToggleFavorite}
                onClickX={() => {
                  Mixpanel.track('Clicked X to unset category filter', { category });
                  scrollToMainTitle();
                  setSelectedCategory(undefined);
                }}
              />
              {index < orderedCategories.length - 1 && (
                <hr className='border-t border-gray-200 mt-16 pb-16' />
              )}
            </div>
          );
        })
      )}
      {selectedCategory && PARTNER_COLLECTION_CATEGORIES[selectedCategory] ? (
        <div className='pt-4'>
          <hr></hr>
          <div className={`w-full text-center pt-4 lg:pt-16 text-2xl text-emerald-600 font-light`}>
            Explore dozens of other expert-crafted templates
          </div>
          <div className='pt-6 max-w-sm mx-auto'>
            <Button
              variant='primary'
              onClick={() => {
                Mixpanel.track('Clicked View All Templates', { selectedCategory });
                scrollToMainTitle();
                setSelectedCategory(undefined);
              }}>
              View All Templates
            </Button>
          </div>
        </div>
      ) : (
        <div
          className={`w-full text-center pt-8 text-xl text-emerald-600 font-light ${orderedCategories.length > 0 ? 'border-t ' : ''}`}>
          Want to see other templates added?{' '}
          <button
            role='link'
            onClick={() => {
              Mixpanel.track('Clicked Suggest a Template', {
                selectedCategory: selectedCategory,
                searchTerm,
                selectedFormats,
              });
              // navigate in a new tab to avoid losing the current state
              const anchorEl = document.createElement('a');
              anchorEl.href = 'https://forms.gle/1KKDZMhSoiKzh48q8';
              anchorEl.target = '_blank';
              anchorEl.click();
            }}
            className='underline hover:text-emerald-500'>
            Let us know here!
          </button>
        </div>
      )}
    </div>
  );
};

const HeaderRow = ({
  selectedFormats,
  setSelectedFormats,
  searchTerm,
  setSearchTerm,
  setSelectedCategory,
  selectedCategory,
  templates,
}) => {
  const { ref, isSticky } = useSticky();
  const getCategoryOptions = () => {
    let options = Object.keys(templates);
    // sort by rank
    options.sort((a, b) => {
      // strip out 'Partner Collections: ' to compare
      return templates[a].order - templates[b].order;
    });

    // only include if category is in OPTION_TO_ICON
    options = options.filter((category) => {
      return Object.keys(OPTION_TO_ICON).includes(category);
    });

    for (const category in PARTNER_COLLECTION_CATEGORIES) {
      if (options.includes(category)) {
        // add "partner collections:" prefix to the category
        options[options.indexOf(category)] = `Partner Collections: ${category}`;
      }
    }
    return options;
  };
  return (
    <div
      ref={ref}
      id='filter-buttons-container'
      className='w-full flex items-center pb-5 z-10 -mb-14 sm:sticky sm:top-0 justify-end bg-white pt-4'>
      <div className='flex items-center relative sm:flex-grow-0 flex-grow w-full'>
        <input
          id='search-input'
          type='text'
          value={searchTerm}
          placeholder='Search'
          autoComplete='off'
          className={`z-20 absolute right-0 border-gray-400 bg-white cursor-pointer text-gray-900 py-1.5 px-3 mr-3 rounded pr-9 h-10
            transition-all duration-500 transform hover:bg-gray-100 
            focus:border-emerald-500 focus:ring-emerald-500 focus:pl-4 focus:cursor-auto focus:w-full sm:focus:w-56 focus:placeholder-gray-400
            ${searchTerm ? 'w-full sm:w-56 pl-4' : 'w-12 '}`}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
        />
        <MagnifyingGlassIcon
          className={`z-20 h-5 w-auto mx-2 absolute cursor-pointer text-gray-800 right-5 ${searchTerm ? 'hidden' : 'visible'}`}
          onClick={() => {
            Mixpanel.track('Focused search input');
            document.getElementById('search-input').focus();
          }}
        />
        <XMarkIcon
          className={`z-20 h-5 w-auto mx-2 absolute cursor-pointer text-gray-800 right-5 ${searchTerm ? 'visible' : 'hidden'}`}
          onClick={() => {
            Mixpanel.track('Cleared search input');
            setSearchTerm('');
          }}
        />
      </div>
      <div className='relative z-10'>
        <Select
          variant='secondary'
          options={templateConstants.FORMAT_NAMES}
          selected={selectedFormats}
          setselected={(e) => {
            Mixpanel.track('Filtered by Format', { selectedFormats: e });
            setSelectedFormats(e);
          }}
          buttonText='Format'
          className='z-50 h-10'
        />
      </div>
      {isSticky && (
        <div className='hidden lg:block relative z-10 ml-2'>
          <Select
            variant='secondary'
            options={getCategoryOptions()}
            selected={selectedCategory}
            setselected={(e) => {
              setSearchTerm('');
              Mixpanel.track('Filtered by Category', { selectedCategory: e });
              if (!e) {
                setSelectedCategory(undefined);
              } else {
                const newVal = e.replace('Partner Collections: ', '');
                setSelectedCategory(newVal);
              }
            }}
            buttonText='Category'
            className='z-50 h-10'
          />
        </div>
      )}
    </div>
  );
};

const GridImageLoading = () => {
  return <div className='animate-pulse h-52 w-full bg-gray-200'></div>;
};

const ActivitySection = ({
  category,
  selectedCategory,
  onClickX,
  templates,
  onClickViewMore,
  onClickActivity,
  onToggleFavorite,
  numLoadedInCategory,
}) => {
  const { isSignedIn } = useAuth();
  const { user } = useUser();
  const { diffitUser } = useContext(DiffitUserContext);
  const hasPremium = isPremiumUser(user, diffitUser);
  const { ref, isSticky } = useSticky();

  if (!templates?.activities) {
    return null;
  }

  const numActivities = templates.activities.length;
  const totalActivities = templates.total;

  const getCategoryName = (category) => {
    if (PARTNER_COLLECTION_CATEGORIES[category]) {
      return `Partner Collections: ${category}`;
    }
    return category;
  };

  return (
    <>
      <div className='mb-8 flex flex-col gap-y-8'>
        <div
          ref={ref}
          className={`${isSticky ? '' : ''} text-xs sm:text-base max-w-[150px] w-fit sm:max-w-none -mt-5 pt-5 sm:sticky sm:top-0 sm:z-20`}>
          {selectedCategory === category ? (
            <div
              className={`w-fit flex bg-gray-100 px-5 sm:px-3 py-2 font-semibold rounded-full items-center justify-center`}>
              {category === 'My Custom Templates' ? 'Custom' : category}
              <button onClick={onClickX}>
                <XMarkIcon className='ml-1.5 h-5 w-5 text-black' />
              </button>
            </div>
          ) : (
            <p className='flex w-full text-left text-2xl font-medium'>
              {getCategoryName(category)}
            </p>
          )}
        </div>
        <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-14 sm:gap-18 justify-center justify-items-center'>
          {templates.activities === undefined ? (
            [...Array(ACTIVITIES_PAGE_SIZE).keys()].map((i) => {
              return (
                <div key={i} className='m-2'>
                  <GridImageLoading />
                </div>
              );
            })
          ) : (
            <>
              {[...Array(numActivities).keys()].map((i) => {
                const template = templates.activities?.[i];
                if (!template) {
                  return null;
                }

                const isJeopardy = isJeopardyTemplate(template);
                const orienation =
                  template.orientation === '8.5x11'
                    ? 'portrait'
                    : template.orientation === '16x9' || isJeopardy
                      ? 'landscape'
                      : 'none';

                const isFavorited = template.is_favorite;
                let titleImage = template.template_image;
                try {
                  const previewImages = JSON.parse(template.preview_images);
                  if (previewImages?.length > 0) {
                    titleImage = previewImages[0];
                  }
                } catch (e) {
                  console.log('Error parsing preview images, falling back to template_image', e);
                }

                return (
                  <div key={i} className='w-full relative group'>
                    <button
                      key={i}
                      onClick={() => {
                        onClickActivity(template);
                      }}
                      style={{ aspectRatio: 324 / 244 }}
                      className='cursor-pointer flex w-[350px] sm:w-[500px] md:w-full flex-col group relative'>
                      <div
                        style={{ aspectRatio: 324 / 244 }}
                        className={` relative flex flex-col w-full justify-center items-center bg-gray-100 rounded-xl group-hover:bg-gray-200`}>
                        {titleImage ? (
                          <div className='relative w-full h-full'>
                            {orienation !== 'none' && (
                              <Image
                                style={{ aspectRatio: 324 / 244 }}
                                src={orienation === 'landscape' ? SlidesBgPng : WorkbookBgPng}
                                className={`absolute top-0 left-0 w-full h-full px-8`}
                              />
                            )}
                            <Image
                              style={{ aspectRatio: 324 / 244 }}
                              src={titleImage}
                              className={`absolute top-0 left-0  w-full h-full rounded-md ${orienation === 'none' ? '' : 'py-8 px-14'} object-scale-down `}
                            />
                          </div>
                        ) : (
                          <div
                            className='justify-center content-center h-52 w-auto px-6 '
                            style={{ aspectRatio: 324 / 244 }}>
                            <div className='bg-emerald-200 px-6 py-10 rounded-md'>
                              <p className='font-semibold text-xl px-4 font-[nunito] text-center'>
                                {template.title}
                              </p>
                              <p className='text-xs text-gray-800 text-center'>Custom Template</p>
                            </div>
                          </div>
                        )}
                      </div>
                      <p className='mt-3 text-gray-900 text-left text-lg'>{template.title}</p>
                      {template.subtitle && (
                        <p className='text-sm text-gray-500 text-left'>{template.subtitle}</p>
                      )}

                      {diffitUser && !hasPremium && template.is_free_this_month && (
                        <p className='text-sm text-emerald-500'>Free this Month</p>
                      )}
                      {diffitUser && !hasPremium && !template.is_premium && (
                        <p className='text-sm text-emerald-500'>Free Forever</p>
                      )}
                    </button>

                    {isSignedIn && (
                      <button
                        className={`absolute top-3 right-3 ${isFavorited ? '' : 'opacity-0'} group-hover:opacity-100 focus:opacity-100`}
                        onClick={() => {
                          onToggleFavorite(template);
                        }}>
                        <HeartIcon
                          className={`${isFavorited ? 'fill-red-500 stroke-none' : 'text-gray-400'} h-5 w-5  `}
                        />
                      </button>
                    )}
                  </div>
                );
              })}
            </>
          )}
        </div>

        <div
          className={`mt-6 flex text-sm justify-center ${totalActivities - numLoadedInCategory > 0 ? '' : 'hidden'}`}>
          <button
            onClick={onClickViewMore}
            className='px-3 py-2 rounded-md border border-gray-300 text-gray-700 hover:bg-gray-50 '>
            View{' '}
            {numLoadedInCategory > numActivities
              ? ''
              : totalActivities - numActivities < ACTIVITIES_PAGE_SIZE
                ? totalActivities - numActivities
                : ACTIVITIES_PAGE_SIZE}{' '}
            More
          </button>
        </div>
      </div>
    </>
  );
};
